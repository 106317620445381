import * as React from 'react';
import {
  Box, Button, Card, CardActions, CardHeader, Chip, ChipProps, Divider, InputAdornment, OutlinedInput,
  Stack, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography,
} from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import { ProductsType } from '../../../interfaces/Products';
import SyncIcon from '@mui/icons-material/Sync';
import dayjs from 'dayjs';
import ProductService from '../../../templates/Dashboard/Products/api/Products.api';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';

export interface ProductProps {
  sx?: SxProps;
  products?: ProductsType[];
}

// Define your status map
const revenueMap: Record<string, { color: ChipProps['color'] }> = {
  negative: { color: 'error' }, // Red for negative values
  positive: { color: 'success' }, // Green for positive values
  zero: { color: 'default' }, // Default for zero or null
};

// Function to get status based on AdRevenue
const getAdRevenueStatus = (adRevenue: number | null): { color: ChipProps['color'] } => {
  if (adRevenue === null || adRevenue === 0) {
    return revenueMap.zero;
  }
  return adRevenue < 0 ? revenueMap.negative : revenueMap.positive;
};

const ProductsTable: React.FC<ProductProps> = ({ products = [], sx }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredProducts, setFilteredProducts] = React.useState<ProductsType[]>(products);
  const [editIndex, setEditIndex] = React.useState(-1);
  const [updatedCost, setUpdatedCost] = React.useState(0);

  React.useEffect(() => {
    setFilteredProducts(products);
  }, [products])

  // Handle Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filtering logic - based on SKU and ASIN
  const handleFilterChange = (filterValue: string) => {
    const filtered = products.filter(product =>
      product.SellerSKU.includes(filterValue) ||
      product.ASIN.includes(filterValue)
    );
    setFilteredProducts(filtered);
  };

  const paginatedProducts = filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Handle Cost Input Change
  const handleChangeCostInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedCost(parseFloat(event.target.value))
  };

  // Handle edit button click
  const handleEditClick = (index: number) => {
    setEditIndex(index);
    setUpdatedCost(products[index].Cost);
  };

  // Handle save button click
  const handleSaveClick = async (sku: string) => {
    await ProductService.UpdateProductCost(sku, updatedCost);
    window.location.reload();
    setEditIndex(-1); // Exit edit mode
  };

  return (
    <>
      <Card sx={{ p: 2 }}>
        <OutlinedInput
          defaultValue=""
          fullWidth
          placeholder="Search by ASIN or SKU"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          // sx={{ maxWidth: '500px' }}
          onChange={(e) => handleFilterChange(e.target.value)}
        />
      </Card>
      <Card sx={sx}>
        <CardHeader
          action={
            <>
              <Button color="inherit" size="small" startIcon={<SyncIcon />}>
                Sync
              </Button>
              <Button variant='contained' size="small">
                Download
              </Button>
            </>
          }
        />
        <Divider />
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Seller SKU</TableCell>
                <TableCell>FnSKU</TableCell>
                <TableCell>ASIN</TableCell>
                <TableCell>Fulfillable Qty</TableCell>
                <TableCell>Reserved Qty</TableCell>
                <TableCell>Unfulfillable Qty</TableCell>
                <TableCell>Total Quantity</TableCell>
                <TableCell>Marketplace</TableCell>
                <TableCell>Condition</TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell>Ads Spend</TableCell>
                <TableCell>Ads Sales30d</TableCell>
                <TableCell>Ads Revenue</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedProducts.length > 0 ? (
                paginatedProducts.map((Product, index) => {
                  // Product.AdRevenue = (Product.AdSales30d - Product.AdSpend).toFixed(2);
                  // Get the label and color based on AdRevenue
                  const adRevenueValue = parseFloat((Product.AdSales30d - Product.AdSpend).toFixed(2)); // Convert to float for comparison
                  const { color } = getAdRevenueStatus(adRevenueValue);
                  return (
                    <TableRow hover={true} key={Product.SellerSKU} >
                      <TableCell sx={{ minWidth: 200 }}>
                        <Box sx={{ textDecoration: 'none' }} component={Link} target='_blank' to={`https://www.amazon.in/${Product.SellerSKU}/dp/${Product.ASIN}`}>
                          <Typography color={'text.primary'}>
                            {Product.ProductName}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{Product.SellerSKU}</TableCell>
                      <TableCell>{Product.FnSKU}</TableCell>
                      <TableCell>{Product.ASIN}</TableCell>
                      <TableCell>{Product.FulfillableQty}</TableCell>
                      <TableCell>{Product.ReservedQty}</TableCell>
                      <TableCell>{Product.UnfulfillableQty}</TableCell>
                      <TableCell>{Product.TotalQuantity}</TableCell>
                      <TableCell>{Product.Marketplace}</TableCell>
                      <TableCell>{Product.Condition}</TableCell>
                      <TableCell>{Product.LastUpdated ? dayjs(Product.LastUpdated).format('MMM D, YYYY') : 'Not Specified'}</TableCell>
                      <TableCell>{Product.AdSpend}</TableCell>
                      <TableCell>{Product.AdSales30d}</TableCell>
                      <TableCell><Chip color={color} label={adRevenueValue} /></TableCell>
                      {/* <TableCell>{(Product.AdSales30d - Product.AdSpend).toFixed(2)}</TableCell> */}
                      <TableCell key={Product.SellerSKU}>
                        {editIndex === index ? (
                          <OutlinedInput
                            defaultValue={Product.Cost}
                            sx={{ width: '100px' }}
                            onChange={handleChangeCostInput}
                          />
                        ) : (<span>{Product.Cost}</span>)}
                      </TableCell>
                      <TableCell key={Product.SellerSKU}>
                        {editIndex === index ? (<Stack display={'flex'} justifyContent={'space-between'}>
                          <Chip color={'success'} label={'Save'} onClick={() => handleSaveClick(Product.SellerSKU)} />
                          <Chip color={'default'} label={'Cancel'} onClick={() => setEditIndex(-1)} />
                        </Stack>
                        ) : (
                          <Chip color={'default'} label={'Edit'} onClick={() => handleEditClick(index)} />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={16} style={{ textAlign: 'center' }}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
        <Divider />
        <TablePagination
          component="div"
          count={filteredProducts.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </>
  );
}

export default ProductsTable;