import {
    Button, MenuItem, Select, SelectChangeEvent, Stack, TextField
} from "@mui/material";
import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import IntegrationService from "./api/Integration.api";
import { toast } from "react-toastify";
import { UserPayload } from "../../../../interfaces/UserPayload";
import { Helper } from "../../../../utils/Helper";

interface ConnectIntegrationFormProps {
    onSuccess: () => void; // Add this prop
}

const ConnectIntegrationForm: FC<ConnectIntegrationFormProps> = ({ onSuccess }) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [User, setUser] = useState<UserPayload>({ email: '', role: '0', organization: '-' });
    const [selectedAPIType, setSelectedAPIType] = useState<string>('');
    const [formData, setFormData] = useState({
        organization: '',
        api_type: '',
        refresh_token: '',
        channel: ''
    });

    // Fetch user data on mount
    useEffect(() => {
        const User = Helper.getCurrentUser();
        setUser(User);
        // Initialize formData with the fetched user organization
        setFormData(prevData => ({ ...prevData, organization: User.organization }));
    }, []);

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const api_type = event.target.value;
        setSelectedAPIType(api_type);
        setFormData(prevData => ({ ...prevData, api_type })); // Update formData with the selected api_type
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const availableApiType = ['SAPI', 'AAPI'];

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await IntegrationService.createIntegration(formData);
            if (response.ok) {
                toast.success('Organization added successfully!');
                onSuccess(); // Call the onSuccess callback
                setFormData({
                    organization: '',
                    api_type: '',
                    refresh_token: '',
                    channel: ''
                });
            }
            setLoading(false);
        } catch (error) {
            toast.error('Something went wrong! Please try again.');
            setLoading(false);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
                {/* Organization field: read-only with value from User */}
                <TextField
                    name="organization"
                    type="text"
                    label="Organization"
                    value={formData.organization}  // Use formData.organization as the value
                    InputProps={{
                        readOnly: true  // Make the field read-only
                    }}
                    required
                />
                {/* API Type select */}
                <Select
                    name="api_type"
                    value={formData.api_type}
                    onChange={handleSelectChange}
                    label="API Type"
                    required
                >
                    {availableApiType.map((type) => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                </Select>
                {/* Channel field */}
                <TextField
                    name="channel"
                    type="text"
                    label="Channel"
                    value={formData.channel}
                    onChange={onChange}
                    required
                />
                {/* Refresh Token field */}
                <TextField
                    name="refresh_token"
                    type="text"
                    label="Refresh Token"
                    value={formData.refresh_token}
                    onChange={onChange}
                    required
                />
                <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    disabled={loading}
                >
                    Connect
                </Button>
            </Stack>
        </form>
    );
};

export default ConnectIntegrationForm;