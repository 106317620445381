import { CustomFetch } from "../../../../../utils/Fetch/Fetch";

const API_URL = process.env.REACT_APP_API_URL;

interface IntegrationsType {
    organization: string,
    api_type: string,
    refresh_token: string,
    channel: string,
}

const getIntegrations = async () => { // Integrations
    const response = await CustomFetch(API_URL + '/users/i/integrations', {method: 'GET'});
    return response.json();
}

const createIntegration = async (dataBody: IntegrationsType) => {
    const response = await CustomFetch(API_URL + '/users/i/integrations', {
        method: 'POST', 
        body: JSON.stringify(dataBody),
    });
    return response.json();
}

const IntegrationService = {
    getIntegrations,
    createIntegration,
}

export default IntegrationService;