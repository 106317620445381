import * as React from 'react';
import {
  Box, Button, Card, CardActions, CardHeader, Chip, ChipProps, Divider, Table, TableBody, TableCell, TableHead, TablePagination, TableRow
} from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import dayjs from 'dayjs';
// import organizationService from '../../../templates/Organizations/api/Organization.api';
import { SubscriptionsType } from '../../../interfaces/Subscriptions';

export interface SubscriptionsProps {
  sx?: SxProps;
  subscriptions?: SubscriptionsType[];
}

const statusMap: Record<string, { label: string, color: ChipProps['color'] }> = {
  true: { label: 'Active', color: 'success' },
  false: { label: 'Inactive', color: 'error' },
};

const SubscriptionsTable: React.FC<SubscriptionsProps> = ({ subscriptions = [], sx }) => {

  // PAGINATION { start }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredSubscriptions, setFilteredSubscriptions] = React.useState<SubscriptionsType[]>(subscriptions);

  React.useEffect(() => {
    setFilteredSubscriptions(subscriptions);
  }, [subscriptions]);

  // Handle Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedOrganizations = filteredSubscriptions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  // PAGINATION { end }

  // const handleClickStatus = async (organization: string) => {
  //   await organizationService.enableDisableOrganization(organization);
  //   window.location.reload();
  // }

  return (
    <Card sx={sx}>
      <CardHeader title="Organizations" />
      <Divider />
      <Box sx={{ overflowX: 'auto' }}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>Organization</TableCell>
              <TableCell>Key</TableCell>
              <TableCell>Users</TableCell>
              <TableCell>Starts</TableCell>
              <TableCell>Expires</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              subscriptions.length > 0 ? (
                subscriptions.map((subscription) => {
                  const { label, color } = statusMap[subscription.is_active.toString()] ?? { label: 'Unknown', color: 'default' };
                  return (
                    <TableRow hover key={subscription.organization}>
                      <TableCell>{subscription.organization}</TableCell>
                      <TableCell>{subscription.subscription_key}</TableCell>
                      <TableCell>{subscription.no_of_users}</TableCell>
                      <TableCell>{dayjs(subscription.starts_at).format('MMM D, YYYY')}</TableCell>
                      <TableCell>{dayjs(subscription.expire_at).format('MMM D, YYYY')}</TableCell>
                      <TableCell>{dayjs(subscription.created_at).format('MMM D, YYYY')}</TableCell>
                      <TableCell>{dayjs(subscription.updated_at).format('MMM D, YYYY')}</TableCell>
                      <TableCell>
                        {/* <Chip 
                          onClick={() => handleClickStatus(subscription.organization)} 
                          color={color} label={label}
                          variant="outlined"
                          style={{ cursor: 'pointer' }}
                        /> */}
                        <Chip color={color} label={label} variant='outlined' />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                    No data available
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <TablePagination 
        component="div"
        count={filteredSubscriptions.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

export default SubscriptionsTable;