import * as React from 'react';
import {
  Box, Card, CardHeader, Chip, ChipProps, Divider, Table, TableBody, TableCell, TableHead, TablePagination, TableRow
} from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import dayjs from 'dayjs';
import { OrganizationsData } from '../../../interfaces/Organization';
import organizationService from '../../../templates/Organizations/api/Organization.api';

export interface OrganizationProps {
  sx?: SxProps;
  organizations?: OrganizationsData[];
}

const statusMap: Record<string, { label: string, color: ChipProps['color'] }> = {
  true: { label: 'Active', color: 'success' },
  false: { label: 'Inactive', color: 'error' },
};

const AllOrganizations: React.FC<OrganizationProps> = ({ organizations = [], sx }) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredOrganizations, setFilteredOrganizations] = React.useState<OrganizationsData[]>(organizations);

  React.useEffect(() => {
    setFilteredOrganizations(organizations);
  }, [organizations]);

  // Handle Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedOrganizations = filteredOrganizations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleClickStatus = async (organization: string) => {
    await organizationService.enableDisableOrganization(organization);
    window.location.reload();
  }

  return (
    <Card sx={sx}>
      <CardHeader title="Organizations" />
      <Divider />
      <Box sx={{ overflowX: 'auto' }}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Phone</TableCell>
              {/* <TableCell sortDirection="desc">Date</TableCell> */}
              <TableCell>Email</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              paginatedOrganizations.length > 0 ? (
                paginatedOrganizations.map((organization) => {
                  //   const { label, color } = statusMap[order.status] ?? { label: 'Unknown', color: 'default' };
                  const { label, color } = statusMap[organization.isActive.toString()] ?? { label: 'Unknown', color: 'default' };

                  return (
                    <TableRow hover key={organization.organization}>
                      <TableCell>{organization.name}</TableCell>
                      <TableCell>{organization.owner}</TableCell>
                      <TableCell>{organization.phone}</TableCell>
                      <TableCell>{organization.email}</TableCell>
                      <TableCell>{dayjs(organization.createdAt).format('MMM D, YYYY')}</TableCell>
                      <TableCell>
                        <Chip
                          onClick={() => handleClickStatus(organization.organization)}
                          color={color} label={label}
                          variant="outlined"
                          style={{ cursor: 'pointer' }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                    No data available
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <TablePagination
        component="div"
        count={paginatedOrganizations.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

export default AllOrganizations;