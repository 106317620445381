import { CustomFetch } from "../../../utils/Fetch/Fetch";
import { Helper } from "../../../utils/Helper";

const API_URL = process.env.REACT_APP_API_URL;

const signUp = async (name: string, email: string, password: string, organization: string, role: string) => {
    const response = await CustomFetch(API_URL + '/auth/sign-up', {
        method: 'POST',
        body: JSON.stringify({ name: name, email: email, password: password, organization: organization, role: role }),
    });
    let responseData = await response.json();
    // console.log('responseData ------------- ', responseData);
    let userData = responseData.data;
    if (!userData.token) return false;
    let token = userData.token;
    Helper.setStorageData('userToken', JSON.stringify(token));
    Helper.setStorageData('user', JSON.stringify({
        email: userData.email,
        role: userData.role,
        organization: userData.organization
    }));
    return true;
}

const signIn = async (email: string, password: string) => {
    const response = await fetch(API_URL + '/auth/sign-in', {
        method: 'POST',
        body: JSON.stringify({ email: email, password: password }),
        headers: { 'Content-Type': 'application/json', 'ngrok-skip-browser-warning': 'true' }
    });
    let responseData = await response.json();
    let userData = responseData.data;
    if (!userData.token) return false;
    let token = userData.token;
    Helper.setStorageData('userToken', JSON.stringify(token));
    Helper.setStorageData('user', JSON.stringify({
        email: userData.email,
        role: userData.role,
        organization: userData.organization
    }));
    return true;
}

const AuthService = {
    signUp, signIn
}
export default AuthService;