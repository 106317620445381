import PageContainer from "../../../components/PageContainer/PageContainer";
import { config } from "../../../config";
import Layout from "../Layout"

interface SubscriptionsProps {

}

const Subscriptions = (props: SubscriptionsProps) => {

    return (
        <>
            <PageContainer title={'Subscriptions | Dashboard | ' + config.site.name} description='This is Subscriptions page of Virtacc'>
                <Layout>
                    <>00</>
                </Layout>
            </PageContainer>
        </>
    );
};

export default Subscriptions;