import { CustomFetch } from "../../../../utils/Fetch/Fetch";

const API_URL = process.env.REACT_APP_API_URL;

const getPPCDataSample = async () => {
    // this is demo data from local api
    try {
        const response = await CustomFetch( API_URL + '/demoData/report', { method: 'GET' } );
        return response.json()
    } catch (error) {
        return error;
    }
}

const getPPCDataFromAPI = async (dataBody: any) => {
    const response = await CustomFetch(API_URL + '/ads/test-report', { method: 'POST', body: dataBody });
    return response.json();
}

const getPPCDataFromDB = async () => {
    try {
        const response = await CustomFetch(API_URL + '/ads/ppcData', { method: 'GET' });
        return response.json();
    } catch (error) {
        return error;
    }
}

const PPCService = {
    getPPCDataSample,
    getPPCDataFromAPI,
    getPPCDataFromDB,
}

export default PPCService;