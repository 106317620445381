import { NavItemConfig } from '../../../interfaces/NavItemConfig';
import { paths } from '../../../paths';

export const navItems = [
  // { key: 'customers', title: 'Customers', href: paths.dashboard.customers, icon: 'people-outline' },
  // { key: 'users', title: 'Users', href: paths.dashboard.users, icon: 'people-outline' },
  // { key: 'integrations', title: 'Integrations', href: paths.dashboard.integrations, icon: 'hub' },
  // { key: 'upload', title: 'Upload', href: paths.dashboard.upload, icon: 'hub' },
  // { key: 'account', title: 'Account', href: paths.dashboard.account, icon: 'manage-account' },

  { key: 'dashboard', title: 'Dashboard', href: paths.dashboard.overview, icon: 'analytics' },
  { key: 'products', title: 'Products', href: paths.dashboard.products, icon: 'shopping-bag' },
  { key: 'orders', title: 'Orders', href: paths.dashboard.orders, icon: 'shopping-cart' },
  { key: 'charts', title: 'Charts', href: paths.dashboard.charts, icon: 'pie-chart' },
  // { key: 'expense', title: 'Expenses', href: paths.dashboard.expenses, icon: 'credit-card' },
  // { key: 'reports', title: 'Reports', href: paths.dashboard.reports, icon: 'download' },
  {
    key: 'ppc', title: 'PPC', icon: 'currency-rupee', items: [
      { key: 'dashboard', title: 'Dashboard', href: paths.dashboard.ppc.dashboard, icon: 'analytics' },
      { key: 'recommendations', title: 'Recommendations', href: paths.dashboard.ppc.recommendations, icon: 'recommend' },
      { key: 'automation-log', title: 'Automation Log', href: paths.dashboard.ppc.autoLog, icon: 'file-log' },
    ]
  },
  // {
  //   key: 'inventory', title: 'Inventory', icon: 'inventory', items: [
  //     { key: 'planner', title: 'Planner', href: paths.dashboard.inventory.planner, icon: 'file-log' },
  //     { key: 'purchase-orders', title: 'Purchase Orders', href: paths.dashboard.inventory.purchaseOrders, icon: 'notepad' },
  //     { key: 'fba-shipments', title: 'FBA Shipments', href: paths.dashboard.inventory.fbaShipments, icon: 'shipment' },
  //     { key: 'suppliers', title: 'Suppliers', href: paths.dashboard.inventory.suppliers, icon: 'person-connect' },
  //   ]
  // },
  // {
  //   key: 'autoresponder', title: 'Autoresponder', icon: 'email', items: [
  //     { key: 'campaigns', title: 'Campaigns', href: paths.dashboard.autoresponder.campaigns, icon: 'campaign' },
  //     { key: 'products', title: 'Products', href: paths.dashboard.autoresponder.products, icon: 'shopping-bag' },
  //     { key: 'orders', title: 'Orders', href: paths.dashboard.autoresponder.orders, icon: 'shopping-cart' },
  //   ]
  // },
  // {
  //   key: 'money-back', title: 'Money Back', icon: 'sync', items: [
  //     { key: 'Lost-and-damaged', title: 'Lost & Damaged', href: paths.dashboard.moneyback.lostAndDamaged, icon: 'cancel-circle' },
  //     { key: 'returns', title: 'Returns', href: paths.dashboard.moneyback.returns, icon: 'undo' },
  //     { key: 'fba-fee-changes', title: 'FBA Fee Changes', href: paths.dashboard.moneyback.fbaFeeChanges, icon: 'change-circle' },
  //   ]
  // },
  // {
  //   key: 'alerts', title: 'Alerts', icon: 'alert-add', items: [
  //     { key: 'dashboard', title: 'Dashboard', href: paths.dashboard.alerts.dashboard, icon: 'analytics' },
  //     { key: 'settings', title: 'Settings', href: paths.dashboard.alerts.settings, icon: 'settings' },
  //   ]
  // },
  {
    key: 'settings', title: 'Settings', icon: 'settings', items: [
      { key: 'general', title: 'General', href: paths.dashboard.settings.general, icon: 'tuning' },
      { key: 'integration', title: 'Integrations', href: paths.dashboard.settings.integrations, icon: 'tuning' },
      { key: 'users', title: 'Users', href: paths.dashboard.settings.users, icon: 'people-outline' },
      { key: 'subscriptions', title: 'Subscriptions', href: paths.dashboard.settings.subscriptions, icon: 'people-outline' },
      { key: 'automation', title: 'Automation', href: paths.dashboard.settings.automation, icon: 'auto-fix' },
      { key: 'tell-a-friend', title: 'Tell a friend', href: paths.dashboard.settings.tellAFriend, icon: 'person-add' },
      { key: 'billing', title: 'Billing', href: paths.dashboard.settings.billing, icon: 'payment-bill' },
    ]
  },
] satisfies NavItemConfig[];
