import React from 'react';
import { IndianRupee, TrendingUp, BarChart3, Calculator, Star } from 'lucide-react';
import { Button, Card, CardContent, Grid, Typography, Box } from '@mui/material';
import { paths } from '../../paths';

const LandingPage = () => {
    const metrics = [
        { label: 'Net profit', value: '₹10,960', change: '+113.6%', color: 'green' },
        { label: 'Revenue', value: '₹76,318', change: '+122.4%', color: 'green' },
        { label: 'Orders', value: '51,198', change: '-9.7%', color: 'red' },
        { label: 'Gross profit', value: '₹23,639', change: '+113.6%', color: 'green' }
    ];
    const signIN = () => window.location.href = paths.auth.signIn

    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: 'white' }}>
            {/* Navigation */}
            <Box sx={{ backgroundColor: '#1E3A8A', padding: 2 }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'white' }}>
                        <BarChart3 style={{ width: 32, height: 32, marginRight: 8, color: 'white' }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Virtacc
                        </Typography>
                    </Box>
                    <Box>

                    </Box>
                    <Button onClick={signIN} variant="outlined" sx={{ borderColor: 'white', color: 'white', '&:hover': { backgroundColor: '#F3F4F6', color: 'black' } }}>
                        Login
                    </Button>
                </Grid>
            </Box>

            {/* Hero Section */}
            <Box sx={{ paddingY: 8, paddingX: 4, backgroundColor: '#1E3A8A' }}>
                <Box sx={{ textAlign: 'center', maxWidth: 'lg', margin: '0 auto' }}>
                    <Typography variant="h3" sx={{ color: 'white', fontWeight: 'bold', marginBottom: 2 }}>
                        Your Complete E-commerce Accounting Solution
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'grey.200', marginBottom: 4 }}>
                        Track profits, analyze costs, and grow your Amazon business with real-time financial insights
                    </Typography>
                    <Button variant="contained" color="success" sx={{ paddingY: 2, paddingX: 4, fontSize: '1rem' }}>
                        Start Free Trial
                    </Button>
                </Box>
            </Box>

            {/* Testimonials Section */}
            <Box sx={{ paddingY: 8, paddingX: 4, backgroundColor: '#F9FAFB' }}>
                <Box sx={{ maxWidth: 'lg', margin: '0 auto' }}>
                    <Grid container spacing={4}>
                        {/* Testimonial 1 */}
                        <Grid item xs={12} md={6}>
                            <Card sx={{ padding: 4, boxShadow: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', marginBottom: 2 }}>
                                    {[...Array(5)].map((_, i) => (
                                        <Star key={i} style={{ width: 24, height: 24, color: '#FBBF24' }} />
                                    ))}
                                </Box>
                                <Typography variant="h6" sx={{ textAlign: 'center', fontStyle: 'italic', marginBottom: 2 }}>
                                    "Incredible breakdown by cohorts and easy to digest data. Nothing comes close to ease of use, price and support from the team."
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <img src="/api/placeholder/48/48" alt="Rajesh Kumar" style={{ borderRadius: '50%', width: 48, height: 48 }} />
                                    <Box sx={{ marginLeft: 2 }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Rajesh Kumar</Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>Top Amazon Seller, Electronics Category</Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>

                        {/* Testimonial 2 */}
                        <Grid item xs={12} md={6}>
                            <Card sx={{ padding: 4, boxShadow: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', marginBottom: 2 }}>
                                    {[...Array(5)].map((_, i) => (
                                        <Star key={i} style={{ width: 24, height: 24, color: '#FBBF24' }} />
                                    ))}
                                </Box>
                                <Typography variant="h6" sx={{ textAlign: 'center', fontStyle: 'italic', marginBottom: 2 }}>
                                    "The automation of Amazon fee calculations has saved us countless hours. Essential tool for any serious seller."
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <img src="/api/placeholder/48/48" alt="Priya Sharma" style={{ borderRadius: '50%', width: 48, height: 48 }} />
                                    <Box sx={{ marginLeft: 2 }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Priya Sharma</Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>Founder, HomeStyle India</Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            {/* Dashboard Preview Section */}
            <Box sx={{ paddingY: 8, paddingX: 4, backgroundColor: 'white' }}>
                <Box sx={{ maxWidth: 'lg', margin: '0 auto', textAlign: 'center' }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1E3A8A', marginBottom: 4 }}>
                        Unlimited Custom Dashboards
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary', marginBottom: 8 }}>
                        Take control of your metric tracking. Monitor KPIs from high-level P&L metrics to detailed marketing campaign data in beautiful, comprehensive dashboards.
                    </Typography>

                    {/* Metrics Grid */}
                    <Grid container spacing={4}>
                        {metrics.map((metric, index) => (
                            <Grid item xs={12} md={3} key={index}>
                                <Card sx={{ padding: 3 }}>
                                    <CardContent>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>{metric.label}</Typography>
                                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metric.value}</Typography>
                                        <Typography variant="body2" sx={{ color: metric.color }}>
                                            {metric.change}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>

            {/* Call to Action Section */}
            <Box sx={{ paddingY: 8, paddingX: 4, backgroundColor: '#1E3A8A', color: 'white' }}>
                <Box sx={{ textAlign: 'center', maxWidth: 'lg', margin: '0 auto' }}>
                    <Typography variant="h3" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                        Ready to Take Control of Your Finances?
                    </Typography>
                    <Typography variant="h6" sx={{ marginBottom: 4 }}>
                        Join successful Indian sellers who trust Virtacc with their financial analytics
                    </Typography>
                    <Button variant="contained" color="success" sx={{ paddingY: 2, paddingX: 4, fontSize: '1rem' }}>
                        Start Free Trial
                    </Button>
                </Box>
            </Box>

            {/* Footer */}
            {/* <Box sx={{ backgroundColor: '#1E3A8A', color: 'white', paddingY: 4 }}>
                <Box sx={{ maxWidth: 'lg', margin: '0 auto' }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Virtacc</Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                Your complete e-commerce accounting solution
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Product</Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>Features</a>
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>Pricing</a>
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>FAQ</a>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Company</Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                About
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                Careers
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                Privacy
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Follow Us</Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>Twitter</a>
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>LinkedIn</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box> */}
            <Box sx={{ backgroundColor: '#1E3A8A', color: 'white', paddingY: 4 }}>
                <Box sx={{ maxWidth: 'lg', margin: '0 auto', paddingX: 2 }}>
                    <Grid container spacing={4}>
                        {/* Virtacc Info */}
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Virtacc</Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                Your complete e-commerce accounting solution
                            </Typography>
                        </Grid>

                        {/* Product Links */}
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Product</Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>Features</a>
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>Pricing</a>
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>Integrations</a>
                            </Typography>
                        </Grid>

                        {/* Company Links */}
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Company</Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>About</a>
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>Careers</a>
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>Privacy</a>
                            </Typography>
                        </Grid>

                        {/* Follow Us Links */}
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Follow Us</Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>Twitter</a>
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>LinkedIn</a>
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* Footer Bottom Section */}
                    <Box sx={{ borderTop: '1px solid', borderColor: '#334155', paddingTop: 3, marginTop: 4 }}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Typography variant="body2" sx={{ color: 'grey.300' }}>
                                © 2024 Virtacc. All rights reserved.
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 3 }}>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>Privacy Policy</a>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>Terms of Service</a>
                                <a href="#" style={{ color: 'inherit', textDecoration: 'none' }}>Security</a>
                            </Box>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default LandingPage;
